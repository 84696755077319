import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { basePage } from 'src/app/classes/basePage';
import { Detail } from 'src/app/classes/models/Detail';
import { AuthService } from 'src/app/services/auth.service';
import { ApiClientService } from 'src/app/services/swagger';
import { HousingDetail, HousingDetailGuest, HousingResponse } from 'src/app/services/swagger/models';
import { Share } from '@capacitor/share';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-housing-editsubstitutes',
  templateUrl: 'editsubstitutes.page.html',
})
export class EditSubstitutesPage extends basePage implements OnInit{

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  voting: any;
  housing: HousingResponse;
  housingDetail: HousingDetail;
  frm: FormGroup;

  constructor(
    private apiClient: ApiClientService,
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService: TranslateService,
    protected frmBuilder: FormBuilder,
    private platform: Platform,
    private iab: InAppBrowser
  ) {
    super(loadingController, modalController, authService, alertCtrl, toastCtrl, translateService);

  }

  ngOnInit() {

    this.form();

  }

  ionViewDidEnter() {

    this.setUIBackButtonAction();

    this.getDetail();
  }

  private get dontCheckoutGuests(): HousingDetailGuest[] {
    return this.housingDetail && this.housingDetail.guests.filter(g => !g.checkout) ?
      this.housingDetail.guests.filter(g => !g.checkout) : [];
  }

  get existsGuests(): boolean{
    return this.guestsSubstitutes.length > 0;
  }

  get guestsSubstitutes(): FormArray {
    return this.frm.get('guests') as FormArray;
  }

  form() {
    this.frm = this.frmBuilder.group({
      id: [null],
      guests: this.frmBuilder.array([]), // array de invitados
    });
  }

  getDetail(){
    this.apiClient.housingDetail(this.user.token, this.condominium.id, this.housing.id).subscribe(data=>{
      this.housingDetail = data.body.data;
      console.log(this.housingDetail);
      this.form();
      this.frm.controls.id.setValue(this.housingDetail.id);
      this.pushGuestsSubstitutes();
    });
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  async edit(){
    await this.showLoading();

    this.apiClient.editSubstitutes(this.user.token, this.condominium.id, this.frm.value).subscribe(async data => {
      await this.hideLoading();
      await this.showToast(this.getText('SUCCESSFUL_EDITSUBSTITUTES_HOUSING'));
      await this.modalController.dismiss();
    },async error=>{ // error 400
      await this.hideLoading();
      this.showError(error);
    });

  }

  pushGuestsSubstitutes() {
    // sumamos el responsable
    const peopleInHouse = this.dontCheckoutGuests.length + 1;
    const limit = this.housingDetail.housingPeopleLimit - peopleInHouse;
    for (let i = 0; i < limit; i++) {
      this.guestsSubstitutes.push(this.createGuestSubstitute());
    }

  }


  createGuestSubstitute(): FormGroup {
    return this.frmBuilder.group({
      name: [null],
      type: ['Adulto'],
    });
  }

}
