import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientService } from './swagger';
import { Condominium, LoginDataResponse, LoginRequest } from './swagger/models';
import { map } from 'rxjs/operators';
import { PushnotificationService } from './pushnotification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private sessionName = 'user';
  private condominiumName = 'condominium';

  private userSubject: BehaviorSubject<LoginDataResponse>;
  public user: Observable<LoginDataResponse>;
  private condominiumSubject: BehaviorSubject<Condominium>;
  public condominium: Observable<Condominium>;

  public get userData(): LoginDataResponse {
    return this.userSubject.value;
  }

  public get condominiumData(): Condominium{
    return this.condominiumSubject.value;
  }

  constructor(private _apiClient: ApiClientService) {
    this.userSubject =
      new BehaviorSubject<LoginDataResponse>(JSON.parse(localStorage.getItem(this.sessionName)));
    this.user = this.userSubject.asObservable();
    this.condominiumSubject =
      new BehaviorSubject<Condominium>(JSON.parse(localStorage.getItem(this.condominiumName)));
    this.condominium = this.condominiumSubject.asObservable();
  }

  login(login: LoginRequest): Observable<LoginDataResponse> {
    login.email = login.email.trim();
    return this._apiClient.login(login).pipe(
      map(data =>{

        const user: LoginDataResponse = data.body.data;
        localStorage.setItem(this.sessionName, JSON.stringify(user));
        this.userSubject.next(user);
        return data.body.data;
      })
    );
  }

  logout() {
    localStorage.removeItem(this.sessionName);
    localStorage.removeItem(this.condominiumName);
    this.userSubject.next(null);
    this.condominiumSubject.next(null);

  }

  setCondominium(condominium: Condominium){
    localStorage.setItem(this.condominiumName, JSON.stringify(condominium));
    this.condominiumSubject.next(condominium);
  }

  setUser(user: LoginDataResponse){
    localStorage.setItem(this.sessionName, JSON.stringify(user));
    this.userSubject.next(user);
  }
}
