import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { basePage } from 'src/app/classes/basePage';
import { Detail } from 'src/app/classes/models/Detail';
import { AuthService } from 'src/app/services/auth.service';
import { ApiClientService } from 'src/app/services/swagger';
import { HousingDetail, HousingDetailGuest, HousingResponse } from 'src/app/services/swagger/models';
import { Share } from '@capacitor/share';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-housing-editdate',
  templateUrl: 'editdate.page.html',
})
export class EditDatePage extends basePage implements OnInit{

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  voting: any;
  housing: HousingResponse;
  frm: FormGroup;

  constructor(
    private apiClient: ApiClientService,
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService: TranslateService,
    protected frmBuilder: FormBuilder,
    private platform: Platform,
    private iab: InAppBrowser
  ) {
    super(loadingController, modalController, authService, alertCtrl, toastCtrl, translateService);

  }

  ngOnInit() {

    this.form();

    this.frm.controls["hourStart"].setValue(this.housing.dateStart.split(' ')[1]);
    this.frm.controls["hourEnd"].setValue(this.housing.dateEnd.split(' ')[1]);
  }

  ionViewDidEnter() {

    this.setUIBackButtonAction();

  }



  form() {
    this.frm = this.frmBuilder.group({
      id: [this.housing.id],
      hourStart: [null, Validators.required],
      hourEnd: [null, Validators.required],
    });
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  async edit(){
    await this.showLoading();
    console.log(this.frm.value);

    const body = {...this.frm.value};
    // si no cambias el tiempo en el componente, lo manda con 5 caracteres, si lo cambias lo manda con 8
    body.hourStart = body.hourStart.length == 5 ? body.hourStart + ':00' : body.hourStart;
    body.hourEnd = body.hourEnd.length == 5 ? body.hourEnd + ':00' : body.hourEnd;

    this.apiClient.editDate(this.user.token, this.condominium.id, body).subscribe(async data => {
      await this.hideLoading();
      await this.showToast(this.getText('SUCCESSFUL_EDITDATE_HOUSING'));
      // cierra el modalñ
      this.modalController.dismiss();
    },async error=>{ // error 400
      await this.hideLoading();
      this.showError(error);
    });

  }


}
