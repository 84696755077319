import { Component, ViewChild } from "@angular/core";
import { IonBackButtonDelegate, ModalController } from "@ionic/angular";

import {  EmergencyDataResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-emergency-detail',
  templateUrl: 'emergency-detail.page.html',
})
export class EmergencyDetailPage {
  emergency: EmergencyDataResponse;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(
    protected modalController: ModalController,
  ) {

  }

  async ionViewDidEnter() {
    this.setUIBackButtonAction();
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

}
