import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiClientService } from "./services/swagger/index";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CondominiumsPage } from './modals/condominiums/condominiums.page';
import { FilterPipe } from './tools/pipes/filter.pipe';
import { DetailPage } from './modals/details/detail.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgCalendarModule } from 'ionic2-calendar';
import { IncidenceDetailPage } from './incidence/detail/incidence-detail.page';
import { EmergencyDetailPage } from './emergency/detail/emergency-detail.page';

import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { VotingDetailPage } from './voting/detail/voting-detail.page';
import { CallNumber } from '@ionic-native/call-number/ngx';
// import { Badge } from '@ionic-native/badge/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ExpenseDetailPage } from './finance/detail/expense-detail.page';
import { PayDetailPage } from './pay/detail/pay-detail.page';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { HousingDetailPage } from './housing/detail/housing-detail.page';
import { ErrorInterceptor } from './classes/interceptors/ErrorInterceptor';
import { EditGuestsPage } from './housing/editGuests/editguests.page';
import { EditSubstitutesPage } from './housing/editSubstitutes/editsubstitutes.page';
import { EditDatePage } from './housing/editDate/editDate.page';
import { ExpenseDetailDownloadPage } from './finance/detaildownload/expense-detaildownload.page';

registerLocaleData(localeEsMx, 'es-MX');

export function createTranslateLoader(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
 //   FilterPipe,
    CondominiumsPage,
    DetailPage,
    IncidenceDetailPage,
    EmergencyDetailPage,
    VotingDetailPage,
    HousingDetailPage,
    EditGuestsPage,
    EditSubstitutesPage,
    EditDatePage,
    PayDetailPage,
    ExpenseDetailPage,
    ExpenseDetailDownloadPage
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    NgCalendarModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApiClientService,
    CallNumber,
  //  Badge,
    InAppBrowser,
    Calendar,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
