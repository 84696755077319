import { Component, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { IonBackButtonDelegate, LoadingController, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Detail } from "src/app/classes/models/Detail";
import { AuthService } from "src/app/services/auth.service";
import { Condominium, LoginDataResponse } from "src/app/services/swagger/models";
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

@Component({
  selector: 'app-detail',
  templateUrl: 'detail.page.html',
})
export class DetailPage{
  item: Detail;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;


  constructor(protected loadingController: LoadingController,
    translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    protected modalController: ModalController,
    private calendar: Calendar,
    private platform: Platform,
  ) {

  }

  ionViewDidEnter() {

    this.setUIBackButtonAction();
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  addCalendar(){
    const arrStartDate = this.item.startDate.split('-');
    const arrEndDate = this.item.endDate.split('-');

    this.calendar.createEventInteractively(
      this.item.title, // title
      undefined, // location
      this.item.description, // notes
      new Date(Number(arrStartDate[0]),Number(arrStartDate[1]) - 1,Number(arrStartDate[2]),0,0,0,0),
      new Date(Number(arrEndDate[0]),Number(arrEndDate[1]) - 1,Number(arrEndDate[2]),23,59,59,0),
    );
  }

}
