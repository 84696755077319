import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { basePage } from "src/app/classes/basePage";
import { AuthService } from "src/app/services/auth.service";
import { PushnotificationService } from "src/app/services/pushnotification.service";
import { Condominium, LoginDataResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-condominiums',
  templateUrl: 'condominiums.page.html',
})
export class CondominiumsPage {

  private user: LoginDataResponse;
  private condominiums: Condominium[];

  constructor(protected loadingController: LoadingController,
    translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    protected modalController: ModalController,
    private pushNotificationService: PushnotificationService
  ) {

    authService.user.subscribe(data => {
      this.user = data;
      this.condominiums = [];
      if(this.user){
        this.condominiums = this.user.condominiums;
      }

    });
  }

  logout(){
    this.authService.logout();
    this.pushNotificationService.desregister();
    this.modalController.dismiss();
    this.router.navigate(['/login']);
  }

  setCondominium(condominium: Condominium){
    this.authService.setCondominium(condominium);
    this.router.navigate(["/main"]);
    this.modalController.dismiss();
  }

}
