import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, Platform, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { basePage } from "src/app/classes/basePage";
import { Detail } from "src/app/classes/models/Detail";
import { AuthService } from "src/app/services/auth.service";
import { ApiClientService } from "src/app/services/swagger";
import { ChargesResponse, Condominium, IncidenceNoteDataResponse, LoginDataResponse, PaysDataResponse, VotingsResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-voting-detail',
  templateUrl: 'pay-detail.page.html',
})
export class PayDetailPage extends basePage{
  pay: PaysDataResponse;
  charges: ChargesResponse[];
  user: LoginDataResponse;
  condominium: Condominium;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(
    private apiClient: ApiClientService,
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService : TranslateService,
    private platform: Platform,
    private iab: InAppBrowser
  ) {
    super(loadingController, modalController, authService, alertCtrl, toastCtrl, translateService);

  }


  ionViewDidEnter() {

    this.setUIBackButtonAction();
    this.getCharges();
  }

  getCharges(){
    this.apiClient.chargesbypayment(
      this.user.token,
      this.condominium.id,
      this.pay.id).subscribe(data=>{
        this.charges = data.body.data;
      });
  }


  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  private download(url: string){

    if(this.platform.is("ios")){
      const browser = this.iab.create(url);
      browser.show();
    }else{
      window.open(url)
    }
  }

}
