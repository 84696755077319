import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Badge } from './classes/models/Badge';
import { CondominiumsPage } from './modals/condominiums/condominiums.page';
import { AuthService } from './services/auth.service';
import { BadgeService } from './services/badge.service';
import { PushnotificationService } from './services/pushnotification.service';
import { ApiClientService } from './services/swagger';
import { Condominium, LoginDataResponse} from './services/swagger/models';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private user: LoginDataResponse;
  private condominium: Condominium;
  private condominiumName: string;
  private logo = "assets/img/logo/horizontal_color.svg";
  public appPages = [];
  public app_version = '1.0.44';
  public lstBadges: Badge[];
  private alreadyConfiguration = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private modalController: ModalController,
    private badgeService: BadgeService,
    private pushNotificationService: PushnotificationService,
    private ngZone: NgZone,
    private apiClient: ApiClientService,
    public menuCtrl: MenuController
  ) {
    authService.user.subscribe(data =>{
      this.user = data;

      if(this.user){
        this.translateService.use(this.user.language);

        if(!this.alreadyConfiguration){

          this.alreadyConfiguration = true;
          setTimeout(()=>{
            this.getConfiguration();
          },1000);

        }
      }
      else
        this.translateService.setDefaultLang('es');
    });

    this.authService.condominium.subscribe(data=>{
      this.condominium = data;

      if(this.condominium){
        this.condominiumName = this.condominium.name;
        this.logo = this.condominium.IconMenuOwnDesign;
        this.createModules();
      }

    });

    this.badgeService.badges.subscribe(data=>{
      this.ngZone.run( () => {
        this.lstBadges = data;
      });
    });

    // idioma
    this.translateService.setDefaultLang('es');

  }


  getConfiguration(){
    if(this.user){
      this.apiClient.getconfiguration(this.user.token).subscribe(data=>{
        this.user.condominiums = data.body.data;
        console.log(this.user);
        this.authService.setUser(this.user);
        this.badgeService.addMultiple(this.user.condominiums);
        if(this.condominium){
          this.condominium = this.user.condominiums.filter(e=>e.id == this.condominium.id)[0];
          this.authService.setCondominium(this.condominium);
        }
      });
    }
  }


  createModules(){
    this.appPages = [];
    this.appPages.push({idModule: null, title: 'HOME', url: '/main', icon: 'inicio_azul.svg' });
    this.appPages.push({ idModule: null, title: 'PROFILE', url: '/profile', icon: 'profile.svg' });
    if(this.condominium.modules_app !== undefined && this.condominium.modules_app !== null){
      this.condominium.modules_app.forEach(m=>{

        this.appPages.push(this.getModule(m.id));
      });
    }
    this.appPages.push({ idModule: null, title: 'TERMS', url: '/terms', icon:'terminos_azul.svg' });

  }

  getModule(idModule : number) : {idModule: number, title: string, url: string, icon: string } {
    switch (idModule) {
      case 1:
        return { idModule: 1, title: 'NEWS',  url: '/news', icon:'noticias_azul.svg' };
      case 2:
        return { idModule: 2, title: 'EVENTS',  url: '/event', icon:'eventos_azul.svg' };
      case 3:
        return { idModule: 3, title: 'VOTING',  url: '/voting', icon:'votacion_azul.svg' };
      case 4:
        return { idModule: 4, title: 'PAYMENTS',  url: '/pay', icon:'pagos_azul.svg' };
      case 5:
        return { idModule: 5, title: 'DIRECTORY', url: '/directory', icon:'directorio-azul.svg' };
      case 6:
        return { idModule: 6, title: 'RESERVATIONS',  url: '/reservations', icon:'calendario_azul.svg' };
      case 7:
        return { idModule: 7, title: 'GUESTS',  url: '/invitation', icon:'Invitación_sobre_azul_1.svg' };
      case 8:
        return { idModule: 8, title: 'INCIDENTS',  url: '/incidence', icon:'incidencia_azul.svg' };
      case 9:
        return { idModule: 9, title: 'EMERGENCIES',  url: '/emergency', icon:'emergencia_azul.svg' };
      case 10:
        return { idModule: 10, title: 'STATEMENTS',  url: '/statements', icon:'estado_cuenta_azul.svg' };
      case 11:
        return { idModule: 11, title: 'QR_MODULE',  url: '/qr', icon:'QR_azul.svg' };
      case 12:
        return { idModule: 12, title: 'FINANCE',  url: '/finance', icon:'finanzas_azul.svg' };
      case 13:
        return { idModule: 13, title: 'DOCUMENTS',  url: '/documents', icon:'documentos_azul.svg' };
      case 14:
        return { idModule: 14, title: 'HOUSING',  url: '/housing', icon:'hospedaje_azul.svg' };
    }
  }

  async openSelectionCondominiums(){
    const modal = await this.modalController.create({
      component: CondominiumsPage,
      keyboardClose: false,
      swipeToClose: false,
      backdropDismiss: false
    });
    await modal.present();
    return await modal.onDidDismiss()

  }

  getCountBadges(idModule: number){

    if(idModule === 7){ // el modulo invitación recibe push notificación pero ira después a otro lado
      return 0;
    }

    return this.lstBadges?.length > 0 ?
      this.lstBadges.filter(e=>e.idCondominium === this.condominium?.id && e.idModule===idModule ).length
      : 0;
  }

  async logout() {
    await this.menuCtrl.close();
    this.authService.logout();
    this.pushNotificationService.desregister();
    this.router.navigate(['/login']);
  }
}
