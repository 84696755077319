import { Component, ViewChild } from "@angular/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { IonBackButtonDelegate, ModalController, Platform } from "@ionic/angular";
import { ApiClientService } from "src/app/services/swagger";

import {  DetailExpense, EmergencyDataResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-expense-detaildownload',
  templateUrl: 'expense-detaildownload.page.html',
})
export class ExpenseDetailDownloadPage {
  expenseDetails: DetailExpense;
  idCondominium: number;
  token: string;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(
    protected modalController: ModalController,
    protected apiClient: ApiClientService,
    private platform: Platform,
    private iab: InAppBrowser
  ) {

  }

  async ionViewDidEnter() {
    this.setUIBackButtonAction();
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

    async downloadXML(){

        this.apiClient.downloadXMLExpense(this.token, this.idCondominium, this.expenseDetails.id).subscribe(data=>{
          this.openURL(data.body.data.url);
        });


    }

    async downloadPDF(){

      this.apiClient.downloadPDFExpense(this.token, this.idCondominium, this.expenseDetails.id).subscribe(data=>{
        this.openURL(data.body.data.url);
      });


  }

    async openURL(url: string){
      // download
      if(this.platform.is("ios")){
       const browser = this.iab.create(url);
       browser.show();
     }else{
       window.open(url)
     }
   }



}
