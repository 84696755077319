import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { basePage } from "src/app/classes/basePage";
import { Detail } from "src/app/classes/models/Detail";
import { AuthService } from "src/app/services/auth.service";
import { ApiClientService } from "src/app/services/swagger";
import { Condominium, IncidenceDataResponse, IncidenceNoteDataResponse, LoginDataResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-incidence-detail',
  templateUrl: 'incidence-detail.page.html',
})
export class IncidenceDetailPage extends basePage implements OnInit{
  incidence: IncidenceDataResponse
  notes: IncidenceNoteDataResponse[];
 // private user: LoginDataResponse;
 // private condominium: Condominium;
  private frm: FormGroup;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(protected loadingCtrl: LoadingController,
    protected modalCtrl: ModalController,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    translateService: TranslateService,
    protected authService: AuthService,
    private router: Router,
    private apiClient: ApiClientService,
    private frmBuilder: FormBuilder,
    protected modalController: ModalController,
  ) {
    super(loadingCtrl, modalCtrl, authService, alertCtrl, toastCtrl, translateService);

    this.authService.user.subscribe(data => {
      this.user = data;
    });
    this.authService.condominium.subscribe(data => {
      this.condominium = data;
    })


  }

  async ionViewDidEnter() {

    await this.getNotes();
    this.setUIBackButtonAction();
  }

  ngOnInit() {
    this.form();
  }

  form() {
    this.frm = this.frmBuilder.group({
      text: [null, Validators.compose([ Validators.required]) ],
    });
  }


  async getNotes() {
    this.apiClient.incidencesnote(this.user.token, this.condominium.id, this.incidence.id).subscribe(async data => {
      this.notes = data.body.data;
    });
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  async addNote(){
    await this.showLoading();
    if (this.frm.valid) {

      this.apiClient.createincidencenote(this.user.token, this.condominium.id, this.incidence.id, this.frm.value)
            .subscribe(async data => {
              await this.hideLoading();
              await this.showToast(this.getText('SUCCESSFUL_INCIDENT_NOTE'));
              this.form();
              await this.getNotes();
            },async error=>{
              await this.hideLoading();
              await this.showError(error);
            });

    } else {
       this.showToast(this.getText('VALIDATION_INCORRECT_FORM_DATA'), 3000, "bottom", 'danger');
    }
  }
}
