import { Component, ViewChild } from "@angular/core";
import { IonBackButtonDelegate, ModalController } from "@ionic/angular";

import {  DetailExpense, EmergencyDataResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-expense-detail',
  templateUrl: 'expense-detail.page.html',
})
export class ExpenseDetailPage {
  lstExpenseDetail: DetailExpense[];
  concept: string;

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(
    protected modalController: ModalController,
  ) {

  }

  async ionViewDidEnter() {
    this.setUIBackButtonAction();
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  getTotal(){
    return this.lstExpenseDetail.reduce((ac, e)=> ac += e.amount, 0);
  }

}
