import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { basePage } from 'src/app/classes/basePage';
import { Detail } from 'src/app/classes/models/Detail';
import { AuthService } from 'src/app/services/auth.service';
import { ApiClientService } from 'src/app/services/swagger';
import { HousingDetail, HousingResponse } from 'src/app/services/swagger/models';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-housing-detail',
  templateUrl: 'housing-detail.page.html',
})
export class HousingDetailPage extends basePage{

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  voting: any;
  housing: HousingResponse;
  housingDetail: HousingDetail;

  constructor(
    private apiClient: ApiClientService,
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService: TranslateService,
    private platform: Platform,
    private iab: InAppBrowser
  ) {
    super(loadingController, modalController, authService, alertCtrl, toastCtrl, translateService);

  }

  ionViewDidEnter() {

    this.setUIBackButtonAction();

    this.getDetail();
  }

  get stateCheckin(): string {
    let stateCheckin = '';
    switch (this.housing.idStateCheckin) {
      case 1:
        stateCheckin = 'Sin ingreso';
        break;
      case 2:
        stateCheckin = 'Ingresado';
        break;
      case 3:
        stateCheckin = 'Desalojado';
        break;
    }

    return stateCheckin;
  }

  get existsGuests(): boolean{
    return this.housingDetail?.guests.length > 0;
  }

  getDetail(){
    this.apiClient.housingDetail(this.user.token, this.condominium.id, this.housing.id).subscribe(data=>{
      this.housingDetail = data.body.data;
      console.log(this.housingDetail.guests.length);
    });
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

  async download(){

    this.showLoading();
    this.apiClient.housingUrlS3(this.user.token, this.condominium?.id, this.housing.id)
    .subscribe(data=>{
      this.hideLoading();
      this.openURL(data.body.data.url);
      },
      error => this.hideLoading()
    );
  }

  async openURL(url: string){
    // download
    if(this.platform.is('ios')){
      const browser = this.iab.create(url);
      browser.show();
    }else{
      window.open(url);
    }
  }

  async share(){

    this.showLoading();
    this.apiClient.housingUrlS3(this.user.token, this.condominium?.id, this.housing.id)
    .subscribe(async data=>{
        this.hideLoading();
        await Share.share({
          title: this.getText('INVITATION_TO_ENTER'),
          text: this.getText('ACCESS_INVITATION'),
          url: data.body.data.url,
          dialogTitle: this.getText('INVITATION_TO_ENTER'),
        });
      },
      error => this.hideLoading()
    );

   }


}
