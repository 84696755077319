import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Badge } from '../classes/models/Badge';
import { Condominium } from './swagger/models';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  private badgesSubject: BehaviorSubject<Badge[]>;
  public badges: Observable<Badge[]>;
  private lstBadges: Badge[];

  constructor() {
    this.lstBadges = [];
    this.badgesSubject = new BehaviorSubject<Badge[]>(this.lstBadges);
    this.badges = this.badgesSubject.asObservable();

  }

  public add(idModule: number, idCondominium: number, id: number){
    this.lstBadges.push({ id, idModule, idCondominium});
    this.badgesSubject.next(this.lstBadges);
  }

  public addMultiple(condominiums: Condominium[]){
    // this.removeAll(); // limpiamos todo

    for(const condominium of condominiums) {
      for(const id of condominium.notifications.news){
        this.lstBadges.push({ id, idModule: 1, idCondominium: condominium.id });
      }
      for(const id of condominium.notifications.events){
        this.lstBadges.push({ id, idModule: 2, idCondominium: condominium.id });
      }
      for(const id of condominium.notifications.projects){
        this.lstBadges.push({ id, idModule: 3, idCondominium: condominium.id });
      }
    }

    this.badgesSubject.next(this.lstBadges);
  }


  public remove(idModule: number, id: number){
    this.lstBadges = this.lstBadges.filter(e=> (e.idModule !== idModule)
                                                || (e.idModule === idModule && e.id !== id));
    this.badgesSubject.next(this.lstBadges);
  }

  public removeAll(){
    this.lstBadges = [];
    this.badgesSubject.next(this.lstBadges);
  }
}
