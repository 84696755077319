import { AlertController, LoadingController, ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { CondominiumsPage } from "../modals/condominiums/condominiums.page";
import { AuthService } from "../services/auth.service";
import { Condominium, LoginDataResponse } from "../services/swagger/models";


export class basePage{

  protected user: LoginDataResponse;
  protected condominium: Condominium;

  protected elements = 10;
  protected skip = 0;

  protected executing: boolean;

  constructor(protected loadingController: LoadingController,
    protected modalController: ModalController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService : TranslateService
    ){

    this.authService.user.subscribe(data=>{
      this.user = data;
    });

    this.authService.condominium.subscribe(data=>{
      this.condominium = data;
    });

  }

  protected async showLoading(){
    this.executing = true;
    await this.loadingController.create().then((res)=>{
      res.present();
   });
  }

  protected async hideLoading(){
    if (this.loadingController.getTop())
      await this.loadingController.dismiss();

    this.executing = false;

    setTimeout(async ()=>{
      try{
      if(this.loadingController.getTop())
        await this.loadingController.dismiss();
      }catch{
      }
    },1500);
  }

  protected async showConfirm(message: string){

    let alert = await this.alertCtrl.create({
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: "cancel",
          handler: () => {
            return false;
          }
        },
        {
          text: 'Aceptar',
          role: "ok",
          handler: () => {
            return true;
          }
        }
      ]
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();

    return role == 'ok' ? true : false;
  }


  async showToast(
    message,
    time=3000,
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    color: string = 'primary') {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: time,
        position: position,
        color: color
      });

      await toast.present();
  }

  async showError(error: any){
    let message = "";
    if(error.error?.message){
      message += error.error?.message;
    }
    if(error.error?.data && message === ""){
      for (const property in error.error.data) {

        message += error.error.data[property];
      }
    }
    if(message === "" && error.status != 200){
      message = "Error";
    }

    await this.showToast(message, 3000, 'bottom', 'danger');
  }


  public getText(id: string, value? : any){
    let translatedText: string = '';
    if(value == null){
        this.translateService.get(id).subscribe(
            value => {
              translatedText = value;
            }
          );
    }else{
        this.translateService.get(id, {value: value}).subscribe(
            value => {
              translatedText = value;
            }
          );
    }
    return translatedText;
  }


}
