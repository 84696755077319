import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, IonBackButtonDelegate, LoadingController, ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { basePage } from "src/app/classes/basePage";
import { Detail } from "src/app/classes/models/Detail";
import { AuthService } from "src/app/services/auth.service";
import { ApiClientService } from "src/app/services/swagger";
import { Condominium, IncidenceNoteDataResponse, LoginDataResponse, VotingsResponse } from "src/app/services/swagger/models";

@Component({
  selector: 'app-voting-detail',
  templateUrl: 'voting-detail.page.html',
})
export class VotingDetailPage extends basePage{
  voting: any;
  notes: IncidenceNoteDataResponse[];

  @ViewChild(IonBackButtonDelegate, { static: false }) backButton: IonBackButtonDelegate;

  constructor(
    private apiClient: ApiClientService,
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected authService: AuthService,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected translateService : TranslateService,
  ) {
    super(loadingController, modalController, authService, alertCtrl, toastCtrl, translateService);

  }

  ionViewDidEnter() {

    this.setUIBackButtonAction();
  }

  addclass(val) {
    return val % 2 == 0 ? { "linea-green": true } : { "linea-blue": true };
  }
  addclassStatus(val) {
      return val == 3 ? { "no-none": true } : { "is-none": true };
  }

  async takeVoting(id, change, statusProyect){
    if (statusProyect == null || statusProyect == 3) {
      await this.showLoading();
      this.apiClient.emiteVoto(this.user.token, this.condominium.id, id, change).subscribe(async data=>{
        this.voting = data.body.data;
        await this.hideLoading();
        await this.showToast(data.body.message);
      }, async error=>{
        await this.hideLoading();
        await this.showError(error);
      });
    }
  }

  async setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      await this.modalController.dismiss();
    };
  }

}
